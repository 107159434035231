import React from 'react'
import * as pageStyles from '../index.module.scss'
import siteData from '../../../credentials-config'

const Header = () => {
    return (
        <header className={pageStyles.header}>
            <div className={pageStyles.headerLogo}>
                <img
                    src={require('../img/logo.svg').default}
                    width={155}
                    height={18}
                    alt="logo"
                />
            </div>
            <div className={pageStyles.headerInfo}>
                {/*<div className={pageStyles.headerLang}>*/}
                {/*    <div className={pageStyles.country}>*/}
                {/*        <img*/}
                {/*            src={require('../img/en.svg').default}*/}
                {/*            width={20}*/}
                {/*            height={15}*/}
                {/*            alt="logo"*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*    <h2>EN</h2>*/}
                {/*    <div className={pageStyles.countryArrow}>*/}
                {/*        <img*/}
                {/*            src={require('../img/dropdown.svg').default}*/}
                {/*            width={12}*/}
                {/*            height={12}*/}
                {/*            alt="logo"*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</div>*/}
                <a href={siteData.links.platform.id.loginAffiliate}>
                    <div className={pageStyles.headerLogin}>Login</div>
                </a>
                <a target="_blank" href={siteData.links.platform.id.regAffiliate}>
                    <div className={pageStyles.btn}>Join now</div>
                </a>
            </div>
        </header>
    )
}

export default Header
