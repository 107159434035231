import * as pageStyles from '../index.module.scss'
import React, { useState } from 'react'
import { Container } from '../../../components/Container'
import classNames from 'classnames'
import siteData from '../credentials-config'
import { Alert, AlertTitle, Snackbar } from '@mui/material'

const Form = () => {
    const [stateEmail, setStateEmail] = useState('')
    const [error, setError] = useState('')
    const [validEmail, setValidEmail] = useState(false)
    const [stateLink, setStateLink] = useState('')
    const [errorLink, setErrorLink] = useState('')
    const [validLink, setValidLink] = useState(false)
    const [stateName, setStateName] = useState('')
    const [stateMessage, setStateMessage] = useState('')
    const [validMessage, setValidMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [validName, setValidName] = useState(false)
    const [errorName, setErrorName] = useState('')
    const [stateCheckbox, setStateCheckbox] = useState(false)
    const [checkboxError, setCheckboxError] = useState(false)
    const [formSubmitFeedback, setFormSubmitFeedback] = useState<null | {
        message: string
        ok: boolean
    }>(null)
    const [formSubmitionFeedbackOpen, setFormSubmitionFeedbackOpen] =
        useState(false)

    const resetFormState = () => {
        setStateName('')
        setStateEmail('')
        setStateLink('')
        setStateMessage('')
    }

    const somethingWentWrong = () => {
        setFormSubmitionFeedbackOpen(true)
        setFormSubmitFeedback({
            message: 'Something went wrong!',
            ok: false,
        })
    }
    const successfullySent = () => {
        setFormSubmitionFeedbackOpen(true)
        setFormSubmitFeedback({
            message: 'Thank you for your message, we will contact you within 24h.',
            ok: true,
        })
    }

    const handleSubmit = (event: {
        preventDefault: () => void
        target: any
    }) => {
        event.preventDefault()
        let isValid = false
        stateName.length < 1 ? handleBlurName() : ''
        stateMessage.length < 1 ? handleBluMessage() : ''
        stateLink.length < 1 ? handleBlurLink() : ''
        stateEmail.length < 1 ? handleBlurEmail() : ''
        if (validName || validMessage || validLink || validEmail) {
            stateName.length < 1 ? handleBlurName() : ''
            stateMessage.length < 1 ? handleBluMessage() : ''
            stateLink.length < 1 ? handleBlurLink() : ''
            stateEmail.length < 1 ? handleBlurEmail() : ''
        } else if (!stateCheckbox) {
            setCheckboxError(true)
        } else {
            isValid = true
        }

        if (isValid) {
            const myForm = event.target
            const formData = new FormData(myForm) as FormData & {
                entries(): string[][]
            }
            // const grecaptcha = window.grecaptcha
            // if(grecaptcha){
            //     if (typeof grecaptcha !== 'undefined' && !grecaptcha.getResponse()) {
            fetch(
                process.env.NODE_ENV === 'development'
                    ? 'https://affiliate.primexbt.com'
                    : '/',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: new URLSearchParams(formData.entries()).toString(),
                }
            )
                .then((req) => {
                    if (req.status === 200 && req.ok) {
                        successfullySent()
                        resetFormState()
                    } else {
                        somethingWentWrong()
                    }
                })
                .catch((error) => {
                    console.error(error)
                    somethingWentWrong()
                })
        }
    }

    const handleBlurEmail = () => {
        let checkEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
            stateEmail
        )
        if (checkEmail) {
            setValidEmail(false)
            setError('')
        } else {
            setError('The field can contain letters, numbers and symbol "@"')
            setValidEmail(true)
        }
    }

    const handleChangeCheckbox = () => {
        setStateCheckbox(!stateCheckbox)
    }

    const handleBlurLink = () => {
        let checkLink = new RegExp(
            '^(https?:\\/\\/)?' +
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
                '((\\d{1,3}\\.){3}\\d{1,3}))' +
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
                '(\\?[;&a-z\\d%_.~+=-]*)?' +
                '(\\#[-a-z\\d_]*)?$',
            'i'
        ).test(stateLink)
        if (checkLink) {
            setValidLink(false)
            setErrorLink('')
        } else {
            setErrorLink('The field must contain http or https and domain')
            setValidLink(true)
        }
    }

    const handleBluMessage = () => {
        setStateMessage(stateMessage.trim())
        if (stateMessage.length < 1) {
            setErrorMessage('The field cannot be empty')
            setValidMessage(true)
        } else {
            setValidMessage(false)
            setErrorMessage('')
        }
    }

    const handleBlurName = () => {
        setStateName(stateName.trim())
        if (stateName.length < 1) {
            setErrorName('The field cannot be empty')
            setValidName(true)
        } else {
            setValidName(false)
            setErrorName('')
        }
    }

    return (
        <Container>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={formSubmitionFeedbackOpen}
                autoHideDuration={3000}
                onClose={() => {
                    setFormSubmitionFeedbackOpen(false)
                }}
                // key={vertical + horizontal}
            >
                <Alert
                    severity={formSubmitFeedback?.ok ? 'success' : 'error'}
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>
                        {formSubmitFeedback?.ok ? 'Success!' : 'Whoops!'}
                    </AlertTitle>
                    {formSubmitFeedback?.message}
                </Alert>
            </Snackbar>
            <div className={pageStyles.formWrapper}>
                <div className={pageStyles.formImg}>
                    <img
                        src={require('../img/user.png').default}
                        width={670}
                        height={484}
                        alt="logo"
                    />
                </div>
                <h2>Contact us for your personal promo offer!</h2>
                <form
                    method="POST"
                    name={process.env.GATSBY_FORM_NAME || 'dev-prime-partner'}
                    data-netlify="true"
                    className={pageStyles.formContainer}
                    onSubmit={handleSubmit}
                >
                    <input
                        type="hidden"
                        name="form-name"
                        value={
                            process.env.GATSBY_FORM_NAME || 'dev-prime-partner'
                        }
                    />
                    <div className={pageStyles.wrapperInput}>
                        <div
                            className={
                                validName
                                    ? classNames(
                                          pageStyles.textFieldFloating,
                                          pageStyles.textFieldFValid
                                      )
                                    : pageStyles.textFieldFloating
                            }
                        >
                            <input
                                className={pageStyles.textFieldInput}
                                type="text"
                                onBlur={handleBlurName}
                                id="name"
                                name="name"
                                autoComplete="off"
                                inputMode="text"
                                onChange={(event) =>
                                    setStateName(event.target.value)
                                }
                                value={stateName}
                                placeholder="111"
                            />
                            <label
                                className={pageStyles.textFieldLabel}
                                htmlFor="name"
                            >
                                Name
                            </label>
                            <span className={pageStyles.error}>
                                {errorName}
                            </span>
                        </div>
                    </div>
                    <div className={pageStyles.wrapperInput}>
                        <div
                            className={
                                validEmail
                                    ? classNames(
                                          pageStyles.textFieldFloating,
                                          pageStyles.textFieldFValid
                                      )
                                    : pageStyles.textFieldFloating
                            }
                        >
                            <input
                                className={pageStyles.textFieldInput}
                                type="email"
                                onBlur={handleBlurEmail}
                                onChange={(event) =>
                                    setStateEmail(event.target.value)
                                }
                                value={stateEmail}
                                id="email"
                                name="email"
                                autoComplete="off"
                                inputMode="email"
                                placeholder="111"
                            />
                            <label
                                className={pageStyles.textFieldLabel}
                                htmlFor="email"
                            >
                                E-mail
                            </label>
                            <span className={pageStyles.error}>{error}</span>
                        </div>
                    </div>
                    <div className={pageStyles.wrapperInput}>
                        <div
                            className={
                                validLink
                                    ? classNames(
                                          pageStyles.textFieldFloating,
                                          pageStyles.textFieldFValid
                                      )
                                    : pageStyles.textFieldFloating
                            }
                        >
                            <input
                                className={pageStyles.textFieldInput}
                                type="text"
                                id="link"
                                name="link"
                                autoComplete="off"
                                inputMode="url"
                                onBlur={handleBlurLink}
                                onChange={(event) =>
                                    setStateLink(event.target.value)
                                }
                                value={stateLink}
                                placeholder="111"
                            />
                            <label
                                className={pageStyles.textFieldLabel}
                                htmlFor="link"
                            >
                                Link To Your Resource
                            </label>
                            <span className={pageStyles.error}>
                                {errorLink}
                            </span>
                        </div>
                    </div>
                    <div className={pageStyles.wrapperMessage}>
                        <div
                            className={
                                validMessage
                                    ? classNames(
                                          pageStyles.textFieldFloating,
                                          pageStyles.textFieldFValid
                                      )
                                    : pageStyles.textFieldFloating
                            }
                        >
                            <textarea
                                className={pageStyles.textFieldInput}
                                id="message"
                                name="message"
                                autoComplete="off"
                                inputMode="text"
                                onChange={(event) =>
                                    setStateMessage(event.target.value)
                                }
                                value={stateMessage}
                                onBlur={handleBluMessage}
                                placeholder="111"
                            />
                            <label
                                className={pageStyles.textFieldLabel}
                                htmlFor="message"
                            >
                                Message
                            </label>
                            <span className={pageStyles.error}>
                                {errorMessage}
                            </span>
                        </div>
                    </div>
                    <div
                        className={classNames(
                            pageStyles.checkbox,
                            pageStyles.formBtnWrapper
                        )}
                    >
                        <input
                            className={pageStyles.checkboxItem}
                            type="checkbox"
                            id="checkbox"
                            onChange={handleChangeCheckbox}
                        />{' '}
                        <label
                            className={
                                checkboxError ? pageStyles.checkboxError : ''
                            }
                            htmlFor="checkbox"
                        >
                            I Accept the {' '}
                            <span>
                                <a
                                    target="_blank"
                                    href={siteData.links.legalTerms}
                                    className={pageStyles.footerInfoLink}
                                >
                                    Terms & Conditions
                                </a>
                            </span>
                        </label>
                    </div>
                    {/*<div className={pageStyles.recaptchaWrapper}>*/}
                    {/*    /!*<div id="recaptcha" data-netlify-recaptcha="true" />*!/*/}
                    {/*</div>*/}
                    <div className={pageStyles.formBtnWrapper}>
                        <button type={'submit'} className={pageStyles.btn}>
                            Get offer
                        </button>
                    </div>
                </form>
            </div>
        </Container>
    )
}

export default Form
