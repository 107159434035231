import * as pageStyles from '../index.module.scss'
import { Container } from '../../../components/Container'
import React from 'react'
import siteData from '../../../credentials-config'

const Footer = () => {
    return (
        <footer className={pageStyles.footer}>
            <Container className={pageStyles.wrapperFooter}>
                <div>
                    <img
                        src={require('../img/footer.svg').default}
                        width={155}
                        height={18}
                        alt="logo"
                    />
                </div>
                <div className={pageStyles.footerInfo}>
                    <a
                        target="_blank"
                        href={siteData.links.legalTerms}
                        className={pageStyles.footerInfoLink}
                    >
                        Legal Terms
                    </a>
                    {/*<a target="_blank" href={siteData.links.footerInfo.privacyPolicy} className={pageStyles.footerInfoLink}>Privacy Policy</a>*/}
                </div>
            </Container>
        </footer>
    )
}

export default Footer
