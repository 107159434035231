import { constants } from 'os'

export type Config = {
    links: {
        mobiles: {
            appStore: string
            googlePlay: string
        }
        platform: {
            id: {
                reg: string
                login: string
                regAffiliate: string
                loginAffiliate: string
            }
        }
        site: {
            main: string
        }
        social: {
            [key: string]: string
        }
        legalTerms: string
    }
    emails: {
        info: string
        support: string
    }
}

const config: Config = {
    links: {
        mobiles: {
            appStore:
                'https://apps.apple.com/us/app/primexbt-crypto-trading/id1522267195',
            googlePlay:
                'https://play.google.com/store/apps/details?id=com.primexbt.trade',
        },
        platform: {
            id: {
                reg: 'https://primexbt.com/id/sign-up?signup=263879',
                login: 'https://primexbt.com/id/sign-in',
                regAffiliate: 'https://my.primexbt.com/v2/sign/up',
                loginAffiliate: 'https://my.primexbt.com/v2/sign/in',
            },
        },
        site: {
            main: 'https://primexbt.com/',
        },
        social: {
            angel: 'https://angel.co/company/primexbt',
            youtube: 'https://www.youtube.com/channel/UCzH0C03Gy8uHyKr-Y59cwJg',
            twitter: 'https://twitter.com/primexbt',
            facebook: 'https://www.facebook.com/primexbt/',
            telegram: 'https://t.me/PrimeXBT_English',
            reddit: 'https://www.reddit.com/r/PrimeXBT/',
            discord: 'https://discord.gg/yEr8p72pxu',
        },
        legalTerms: 'https://my.primexbt.com/v2/terms',
    },
    emails: {
        info: 'info@primexbt.com',
        support: 'support@help.primexbt.com',
    },
}

export default config
