import React from 'react'
import { IntercomProvider } from 'react-use-intercom'

export const WithIntercom: React.FC<
    React.PropsWithChildren<{ appId?: string; autoBoot?: boolean }>
> = ({ children, appId = 'cr65d8qu', autoBoot = true }) => {
    return (
        <IntercomProvider autoBoot={autoBoot} appId={appId}>
            {children}
        </IntercomProvider>
    )
}
