import classNames from 'classnames'

export const Container: React.FC<{
    children: React.ReactNode
    className?: any
}> = (props) => {
    return (
        <div
            className={classNames(
                'tw-w-[100%] tw-mx-auto tw-max-w-[1264px] tw-px-[16px] tablet:tw-px-[24px]',
                props.className
            )}
        >
            {props.children}
        </div>
    )
}
