import React from 'react'
import * as pageStyles from '../index.module.scss'
import { Container } from '../../../components/Container'
import classNames from 'classnames'

const Banner = () => {
    return (
        <Container>
            <div className={pageStyles.bannerWrapper}>
                <div className={pageStyles.bannerText}>
                    <h1>PrimeXBT Affiliate Program</h1>
                    <h2>Introduce Traders & Earn a Fortune With a Trusted Brand</h2>
                </div>
                <div className={pageStyles.bannerContent}>
                    <div className={pageStyles.bannerContentItem}>
                        <h2>Affiliate commission up to</h2>
                        <h3>$1200</h3>
                        <h4>per client</h4>
                    </div>
                    <div className={classNames(pageStyles.bannerContentItem,pageStyles.bannerContentItemLeft )}>
                        <h2>Introducer commission up to</h2>
                        <h3>70%</h3>
                        <h4>profit share</h4>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Banner