import * as pageStyles from '../index.module.scss'
import React from 'react'
import { Container } from '../../../components/Container'
import siteData from '../../../credentials-config'

const Info = () => {
    return (
        <Container>
            <div className={pageStyles.infoWrapper}>
                <div className={pageStyles.infoContent}>
                    <div className={pageStyles.infoContentItem}>
                        <div className={pageStyles.infoContentCircle}>
                            <div className={pageStyles.infoContentImg}>
                                <img
                                    src={require('../img/sign-up.svg').default}
                                    width={56}
                                    height={56}
                                    alt="logo"
                                />
                            </div>
                        </div>
                        <div className={pageStyles.infoContentText}>
                            <h2>Sign Up</h2>
                            <h3>Register as an affiliate</h3>
                        </div>
                    </div>
                    <div className={pageStyles.infoContentItem}>
                        <div className={pageStyles.infoContentCircle}>
                            <div className={pageStyles.infoContentImg}>
                                <img
                                    src={
                                        require('../img/advertise.svg').default
                                    }
                                    width={56}
                                    height={56}
                                    alt="logo"
                                />
                            </div>
                        </div>
                        <div className={pageStyles.infoContentText}>
                            <h2>Advertise</h2>
                            <h3>
                                Share your referral link with your traffic or
                                network
                            </h3>
                        </div>
                    </div>
                    <div className={pageStyles.infoContentItem}>
                        <div className={pageStyles.infoContentCircle}>
                            <div className={pageStyles.infoContentImg}>
                                <img
                                    src={require('../img/earn.svg').default}
                                    width={56}
                                    height={56}
                                    alt="logo"
                                />
                            </div>
                        </div>
                        <div className={pageStyles.infoContentText}>
                            <h2>Earn</h2>
                            <h3>
                                Receive commissions for anyone who registers
                                with your link and starts trading
                            </h3>
                        </div>
                    </div>
                </div>
                <a target="_blank" href={siteData.links.platform.id.regAffiliate}>
                    <div className={pageStyles.btn}>Join now</div>
                </a>
            </div>
        </Container>
    )
}

export default Info
