import * as pageStyles from './index.module.scss'
import React from 'react'
import Footer from './components/footer'
import Header from './components/header'
import Banner from './components/banner'
import Form from './components/form'
import Info from './components/info'
import Offer from './components/offer'
import { WithIntercom } from '../../components/WithIntercom/withIntercom'

const LandingPage = () => (
    <WithIntercom>
        <div className={pageStyles.page}>
            <div className={pageStyles.wrapperPage}>
                <Header />
                <Banner />
                <Form />
                <Info />
            </div>
            <Offer />
            <Footer />
        </div>
    </WithIntercom>
)

export default LandingPage;
