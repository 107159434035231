import React from 'react'
import LandingPage from './index'


const IndexPage = () => {
    return (
        <LandingPage/>
    )
}

const Head = () => {
    return (
        <>
            <title>Earn a fortune with a trusted brand</title>
            <meta
                name="description"
                content="PrimeXBT allows each trader to create their own strategy which can be followed by thousands of platform users."
            />
        </>
    )
}

export { IndexPage, Head }
