import React from 'react'
import * as pageStyles from '../index.module.scss'
import { Container } from '../../../components/Container'
import siteData from '../../../credentials-config'

const Offer = () => {
    return (
        <div className={pageStyles.offerContainer}>
            <Container>
                <div className={pageStyles.offerWrapper}>
                    <h2>What we offer</h2>
                    <div className={pageStyles.offerContent}>
                        <div className={pageStyles.offerItems}>
                            <div className={pageStyles.offerItem}>
                                <h3>up to</h3>
                                <h4>70%</h4>
                                <h5>Revenue Share</h5>
                                {/*<div className={pageStyles.coin1Wrapper}>*/}
                                {/*    <img*/}
                                {/*        src={*/}
                                {/*            require('../img/coin-1.svg').default*/}
                                {/*        }*/}
                                {/*        width={86}*/}
                                {/*        height={58}*/}
                                {/*        alt="logo"*/}
                                {/*    />*/}
                                {/*</div>*/}
                                {/*<div className={pageStyles.coin2Wrapper}>*/}
                                {/*    <img*/}
                                {/*        src={*/}
                                {/*            require('../img/coin-2.svg').default*/}
                                {/*        }*/}
                                {/*        width={86}*/}
                                {/*        height={58}*/}
                                {/*        alt="logo"*/}
                                {/*    />*/}
                                {/*</div>*/}
                            </div>
                            <div className={pageStyles.offerItem}>
                                <h3>up to</h3>
                                <h4>1200$</h4>
                                <h5>CPA</h5>
                            </div>
                            <div className={pageStyles.offerItem}>
                                <h4>CPL & CPI</h4>
                                <h5>On Demand</h5>
                                {/*<div className={pageStyles.coin3Wrapper}>*/}
                                {/*    <img*/}
                                {/*        src={*/}
                                {/*            require('../img/coin-3.svg').default*/}
                                {/*        }*/}
                                {/*        width={86}*/}
                                {/*        height={58}*/}
                                {/*        alt="logo"*/}
                                {/*    />*/}
                                {/*</div>*/}
                            </div>
                            <div className={pageStyles.offerItem}>
                                <h4>24/7</h4>
                                <h5>Support</h5>
                            </div>
                            {/*<div className={pageStyles.coin5Wrapper}>*/}
                            {/*    <img*/}
                            {/*        src={require('../img/coin-5.svg').default}*/}
                            {/*        width={86}*/}
                            {/*        height={58}*/}
                            {/*        alt="logo"*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*<div className={pageStyles.coin4Wrapper}>*/}
                            {/*    <img*/}
                            {/*        src={require('../img/coin-4.svg').default}*/}
                            {/*        width={68}*/}
                            {/*        height={58}*/}
                            {/*        alt="logo"*/}
                            {/*    />*/}
                            {/*</div>*/}
                        </div>
                        <div className={pageStyles.offerList}>
                            <div className={pageStyles.offerListItem}>
                                <h4>Generous payouts</h4>
                                <ul>
                                    <li>Up to 70% profit share</li>
                                    <li>Up to $1200 per client</li>
                                    <li>Tailor-made offers</li>
                                </ul>
                                {/*<div className={pageStyles.coin21Wrapper}>*/}
                                {/*    <img*/}
                                {/*        src={*/}
                                {/*            require('../img/coin-21.svg')*/}
                                {/*                .default*/}
                                {/*        }*/}
                                {/*        width={76}*/}
                                {/*        height={53}*/}
                                {/*        alt="logo"*/}
                                {/*    />*/}
                                {/*</div>*/}
                                {/*<div className={pageStyles.coin22Wrapper}>*/}
                                {/*    <img*/}
                                {/*        src={*/}
                                {/*            require('../img/coin-22.svg')*/}
                                {/*                .default*/}
                                {/*        }*/}
                                {/*        width={71}*/}
                                {/*        height={52}*/}
                                {/*        alt="logo"*/}
                                {/*    />*/}
                                {/*</div>*/}
                            </div>
                            <div className={pageStyles.offerListItem}>
                                <h4>We pay for every lead</h4>
                                <ul>
                                    <li>Live and transparent statistics</li>
                                    <li>High payouts per each funded client</li>
                                    <li>Payouts once a week</li>
                                </ul>
                            </div>
                            <div className={pageStyles.offerListItem}>
                                <h4>Special offers for your referrals</h4>
                                <ul>
                                    <li>Free funded accounts</li>
                                    <li>Discounts on commissions</li>
                                    <li>Welcome bonuses</li>
                                </ul>
                            </div>
                        </div>
                        {/*<div className={pageStyles.coin} />*/}
                    </div>
                    <a target="_blank" href={siteData.links.platform.id.regAffiliate}>
                        <div className={pageStyles.btn}>Join now</div>
                    </a>
                </div>
            </Container>
        </div>
    )
}

export default Offer
